<template>
    <div>
        <load-spinner v-if="isLodingCreatePayment"></load-spinner>
        <base-header base-title="Payment"></base-header>
        <div
            class="kt-content kt-grid__item kt-grid__item--fluid"
            id="kt_content"
        >
            <div class="kt-portlet kt-portlet--mobile">
                <div class="kt-portlet__head kt-portlet__head--lg">
                    <base-title
                        :head-title="'Create Snap Payment'"
                    ></base-title>
                    <div class="kt-portlet__head-toolbar">
            <div class="kt-portlet__head-wrapper">
               
              <router-link
                class="btn btn-default btn-bold btn-upper btn-font-sm mr-2"
                :to="{ name: 'manajement-payment-id', params: {id: $route.params.id} }"
              >
                <i class="flaticon2-back"></i>
                {{ $t("button.previous") }}
              </router-link>
            </div>
          </div>
                    <!-- <div class="kt-portlet__head-toolbar">
                        <div class="kt-portlet__head-wrapper">
                            <button
                                class="btn btn-sm btn-outline-primary"
                                @click="logout"
                                v-if="isLoginPayment"
                            >
                                <i class="fa fa-arrow-left"></i> Logout
                            </button>
                        </div>
                    </div> -->
                </div>
                <div class="kt-portlet__body">
                    <!-- isi -->
                    <!-- <template v-if="!isLoginPayment">
                        <login-client />
                    </template>
                    <template v-else> -->
                        <create-payment></create-payment>
                    <!-- </template> -->
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BaseTitle from "./../../components/_base/BaseTitle";
import BaseHeader from "./../../components/_base/BaseHeader";
// import LoginClient from '../components/snap/LoginClient.vue'
import CreatePayment from '../components/snap/CreatePayment.vue'
import LoadSpinner from "./../../components/_general/LoadSpinner";
import { mapState, mapActions, mapMutations } from "vuex";
export default {
    components: {
        BaseTitle,
        BaseHeader,
    //    LoginClient,
       CreatePayment,
        LoadSpinner
    },
    computed: {
        ...mapState("PaymentStore", {
            isLodingCreatePayment: state => state.isLodingCreatePayment
        })
    },
    data() {
        return {};
    }
};
</script>

<style></style>
