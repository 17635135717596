<template>
    <div class="row">
        <div class="col-md-10 offset-md-1 card p-3 card">
            <div class="card-header">
                <div class="custom-control custom-switch custom-switch-md">
                    <input
                        type="checkbox"
                        class="custom-control-input"
                        id="customer"
                        v-model="isCustomer"
                    />
                    <label class="custom-control-label py-1" for="customer"
                        >With Customer Detail</label
                    >
                </div>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md-12 form-group">
                        <label for="">Transaction order ID</label>
                        <input type="text" class="form-control" v-model="idTransaction"/>
                    </div>
                </div>
                <template v-if="isCustomer">
                    <fieldset class="p-2">
                        <legend class="float-none w-auto font-weight-bold">
                            Customer
                        </legend>
                        <div class="row">
                            <div class="col-md-6 form-group">
                                <label for="">First Name</label>
                                <input type="text" class="form-control" v-model="customerDetail.first_name"/>
                            </div>
                            <div class="col-md-6 form-group">
                                <label for="">Last Name</label>
                                <input type="text" class="form-control" v-model="customerDetail.last_name"/>
                            </div>
                        </div>
                        <div class="row">
                            <div class="col-md-6 form-group">
                                <label for="">Email</label>
                                <input type="text" class="form-control" v-model="customerDetail.email"/>
                            </div>
                            <div class="col-md-6 form-group">
                                <label for="">No. Telp</label>
                                <input type="text" class="form-control" v-model="customerDetail.phone"/>
                            </div>
                        </div>
                    </fieldset>
                </template>

                <fieldset class="p-2">
                    <legend class="float-none w-auto font-weight-bold">
                        Item
                    </legend>
                    <div class="row">
                        <div class="col-md-12">
                            <button class="btn btn-sm btn-outline-primary mb-2" @click="addItem">Add Item</button>
                            <!-- <button class="btn btn-sm btn-outline-primary" @click="originalPrice">WithOriginal</button> -->
                            <textarea rows="10" v-model="itemStringfy" class="form-control"></textarea>
                        </div>
                    </div>
                    
                </fieldset>
            </div>
            <div class="card-footer">
                <strong>Total : Rp {{total}} </strong>
                <button
                    class="btn btn-sm btn-outline-primary float-right"
                    @click="createSnap"
                >
                    <i class="fas fa-paper-plane"></i> SEND
                </button>
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions} from 'vuex'
export default {
    data() {
        return {
            isCustomer: false,
            items: [
                {
                   name: '',
                   price: 0,
                }
            ],
            itemStringfy: '',
            isSendData: true,
            idTransaction: '',
            customerDetail: {
                email: '',
                first_name: '',
                last_name: '',
                phone: ''
            },
        };
    },
    computed:{
        total(){
            let sumTotal = 0
            let stringfyText = this.itemStringfy
            try{
                let parseItem = JSON.parse(stringfyText)
                sumTotal = parseItem.reduce((acc, item)=> acc + (item.price * (item.quantity ? item.quantity : 1)),0)
            } catch {
                return 0
            }
            return sumTotal
        }
    },
    mounted() {
        this.itemStringfy = this.createNewLine(JSON.stringify(this.items))
    },
    methods: {
        ...mapActions({
            'createSnapPayment': 'PaymentStore/CREATE_SNAP_PAYMENT'
        }),
        createSnap() {
            let customerDetail = {}
            let itemDetails = []
            try{
                itemDetails = JSON.parse(this.itemStringfy)
            } catch{
                toastr.error('Parse Item tidak sesuai')
                return
            }
            if(!this.idTransaction) {
                toastr.error('Silahkan isi Id Transaksi')
                return
            }
            let grossAmount = 0
            if(this.isCustomer){
                customerDetail = {
                    email: this.customerDetail.email,
                    first_name: this.customerDetail.first_name,
                    last_name: this.customerDetail.last_name,
                    phone: this.customerDetail.phone
                }
            }
            grossAmount = itemDetails.reduce((acc, obj) => acc + (obj.price * (obj.quantity ? obj.quantity : 1)) ,0)
            if(!grossAmount) {
                toastr.error('Gross Amount Belum ada')
                return
            }
            const data = {
                transactionOrderId: this.idTransaction,
                ...(this.isCustomer ? {customerDetail} : {}),
                itemDetails,
                grossAmount
            }
            const obj= {
                data,
                userId: this.$route.params.id
            }
            this.createSnapPayment(obj)
        },
        addItem(){
            try{
               const data = JSON.parse(this.itemStringfy)
               this.items = data
               this.items.push({
                   name: '',
                   price: 0
                })
                this.itemStringfy = this.createNewLine(JSON.stringify(this.items))
           } catch {
               toastr.error('Perhatikan Penggunaan Parsing')
           }
        },
        createNewLine(item){
            return item.replaceAll('{', '{\n\t').replaceAll(',', ',\n\t').replaceAll('}', '\n}')
        }
    }
};
</script>

<style></style>
